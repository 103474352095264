/* You can add global styles to this file, and also import other style files */

html, body { background-color: #fff; height: 100%; }
body { margin: 0; font-family: "Trebuchet MS"; }
@import '~material-icons/iconfont/material-icons.scss';

/* default and hover color */
.mat-form-field-appearance-outline .mat-form-field-outline {
  color: #644080;
}

/* focused color */
.mat-form-field-appearance-outline.mat-focused
  .mat-form-field-outline-thick {
  color: #644080;
}

/* floating label color */
.mat-form-field-appearance-outline.mat-focused .mat-form-field-label {
  color: #644080;
}

.mat-form-field-appearance-outline .mat-form-field-label {
  color: #644080;
}

.login_title_1 {
  font-family: "Trebuchet MS";
  font-size: 15px;
}

.login_title_2 {
  font-family: "Trebuchet MS";
  font-size: 15px;
  font-style: italic;
  font-weight: bold;
  color: #115180;
}

.cus_footer {
    position: fixed;
    bottom: 0;
}

button {
  outline: none;
}

::ng-deep .mat-button-focus-overlay {
  display: none;
}

.mat-step-header .mat-step-label.mat-step-label-active {
  color: rgba(0,0,0,.87);
  font-family: 'Trebuchet MS';
  font-size: 17px;
}

.mat-step-header .mat-step-label, .mat-step-header .mat-step-optional {
  color: rgba(0,0,0,.54);
  font-family: 'Trebuchet MS';
  font-size: 17px;
}

.custom-action-buttons{
  display:-ms-flexbox;
  display:flex;
  -ms-flex-align:center;
  align-items:center;
  -ms-flex-pack:end;
  justify-content:flex-end;
}
.custom-action-buttons>:not(:first-child){margin-left:.25rem}
.custom-action-buttons>:not(:last-child){margin-right:.25rem}

.mat-tab-body-content { overflow: hidden!important}

.mat-tab-label, .mat-tab-link {
  color: black;
  font-family: 'Trebuchet MS';
  font-size: 17px;
}

